import React from "react"
import styled from "@emotion/styled"

export const Permalink = styled.a`
  margin-left: 0.25em;
  font-size: 0.8em;
  vertical-align: top;
`
export const Heading = props => {
  let { title, id } = props
  if (!id) {
    id = title.toLowerCase().replace(/[\s]+/g, "-")
  }
  return (
    <h2 id={id}>
      {title}
      <Permalink
        href={`#${id}`}
        title="Permanent link to this section"
        aria-label={`Permanent link to the section: ${title}`}
        rel="permalink"
      >
        <span role="presentation">¶</span>
      </Permalink>
    </h2>
  )
}

export const Missing = styled.span`
  font-weight: normal;
  font-style: italic;
  color: red;
`
