import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/layout";
import { Container } from "../ui/Container";
import { Heading, Missing } from "../ui/Collection";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  ...props
}) => <Layout {...props} title="My Collection">
    <Container>{children}</Container>
  </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Collection`}</h1>
    <p>{`This is the collection of DVDs, Manga, and Magazines I own. Video tapes (one duplicate of the first Harry Potter film) and regular books (I don't read much anymore, I still need to read a number of the pile of books I own and don't need more), and non-physical video games (bought from PSN and installed on my PS3/PS4/PSVita, downloaded to the Wii/Wii U, or bought through Steam) are not listed here.
Things I'm missing or want to add to my collection are also listed here `}<Missing mdxType="Missing">{`like this`}</Missing>{`.`}</p>
    <p>{`This page was primarily for my family who never knew what I have and always had trouble finding me a gift. Nowadays I'm less interested in physical media.`}</p>
    <p>{`The lists are in no particular order. Mostly just randomly inserted as I read them off the shelves and stacks in my room, and appended to when I buy something and there isn't a item it should be put next to.`}</p>
    <Heading title="Anime and related DVDs" mdxType="Heading" />
    <ul>
      <li parentName="ul">{`Castle in the Sky`}</li>
      <li parentName="ul">{`Kiki's Delivery Service`}</li>
      <li parentName="ul">{`Kiki's Delivery Service (Special Edition)`}</li>
      <li parentName="ul">{`Porco Rosso`}</li>
      <li parentName="ul">{`Princess Mononoke`}</li>
      <li parentName="ul">{`The Cat Returns`}</li>
      <li parentName="ul">{`Howl's Moving Castle`}</li>
      <li parentName="ul">{`My Neighbour Totoro`}</li>
      <li parentName="ul">{`My Neighbour Totoro (Special Edition)`}</li>
      <li parentName="ul">{`Nausicaa`}</li>
      <li parentName="ul">{`Nausicaa (Import)`}</li>
      <li parentName="ul">{`Tales from Earthsea (Import)`}</li>
      <li parentName="ul">{`Tales from Earthsea`}</li>
      <li parentName="ul">{`Spirited Away`}</li>
      <li parentName="ul">{`Spirited Away (replacement)`}</li>
      <li parentName="ul">{`Whisper of the Heart`}</li>
      <li parentName="ul">{`Ponyo on the Cliff by the Sea`}</li>
      <li parentName="ul">{`Pom Poko`}</li>
      <li parentName="ul">{`My Neighbours the Yamadas`}</li>
      <li parentName="ul">{`The Borrower Arrietty (Import)`}</li>
      <li parentName="ul">{`The Secret World of Arrietty`}</li>
      <li parentName="ul">{`From up on Poppy Hill`}</li>
      <li parentName="ul">{`The Wind Rises`}</li>
      <li parentName="ul">{`When Marnie Was There`}</li>
      <li parentName="ul">{`Grave of the Fireflies`}</li>
      <li parentName="ul">{`The Tale of the Princess Kaguya`}</li>
      <li parentName="ul">{`Cowboy Bebop`}</li>
      <li parentName="ul">{`Cowboy Bebop Movie`}</li>
      <li parentName="ul">{`Demon Prince Enma 1`}</li>
      <li parentName="ul">{`Demon Prince Enma 2`}</li>
      <li parentName="ul">{`Devil Hunter Yokko`}</li>
      <li parentName="ul">{`Pumpkin Scissors`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Death Note</Missing>
      </li>
      <li parentName="ul">{`Death Note: Relight`}</li>
      <li parentName="ul">{`Death Note: Relight 2`}</li>
      <li parentName="ul">{`Death Note (Live Action)`}</li>
      <li parentName="ul">{`Death Note II (Live Action)`}</li>
      <li parentName="ul">{`Death Note: L Change the World (Live Action)`}</li>
      <li parentName="ul">{`Negima!`}</li>
      <li parentName="ul">{`Negima!?`}</li>
      <li parentName="ul">{`Negima!? Spring and Sumer Specials`}</li>
      <li parentName="ul">{`Black Cat`}</li>
      <li parentName="ul">{`The Law of Ueki`}</li>
      <li parentName="ul">{`The Law of Ueki Volume 2 (duplicate)`}</li>
      <li parentName="ul">{`Saikano`}</li>
      <li parentName="ul">{`Those Who Hunt Elves`}</li>
      <li parentName="ul">{`Magical Girl Lyrical Nanoha`}</li>
      <li parentName="ul">{`Magical Girl Lyrical Nanoha A's`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">
  [Magical Girl Lyrical Nanoha
  StrikerS](http://www.cdjapan.co.jp/product/KIXA-90441)
        </Missing>
      </li>
      <li parentName="ul">
        <Missing mdxType="Missing">
  [Magical Girl Lyrical Nanoha The MOVIE
  1st](http://www.cdjapan.co.jp/product/KIXA-73)
        </Missing>
      </li>
      <li parentName="ul">
        <Missing mdxType="Missing">
  [Magical Girl Lyrical Nanoha The Movie 2nd
  A's](http://www.cdjapan.co.jp/product/KIXA-90284)
        </Missing>
      </li>
      <li parentName="ul">{`Rozen Maiden`}</li>
      <li parentName="ul">{`Rozen Maiden: Traumend`}</li>
      <li parentName="ul">{`Rozen Maiden: Ouvertüre`}</li>
      <li parentName="ul">{`Rozen Maiden: Zurückspulen`}</li>
      <li parentName="ul">{`My Dear Marie`}</li>
      <li parentName="ul">{`Shadow Star Narutaru`}</li>
      <li parentName="ul">{`Blood: The Last Vampire`}</li>
      <li parentName="ul">{`Blood: The Last Vampire (Live action)`}</li>
      <li parentName="ul">{`Blood+ Part I`}</li>
      <li parentName="ul">{`Blood+ Part II`}</li>
      <li parentName="ul">{`Eureka Seven Complete Collection 1`}</li>
      <li parentName="ul">{`Eureka Seven Complete Collection 2`}</li>
      <li parentName="ul">{`Eureka Seven: Good Night, Sleep Tight, Young Lovers`}</li>
      <li parentName="ul">{`Elfen Lied`}</li>
      <li parentName="ul">{`Hellsing`}</li>
      <li parentName="ul">{`Hellsing Ultimate I`}</li>
      <li parentName="ul">{`Hellsing Ultimate II`}</li>
      <li parentName="ul">{`Hellsing Ultimate III`}</li>
      <li parentName="ul">{`Hellsing Ultimate IV`}</li>
      <li parentName="ul">{`Hellsing Ultimate V`}</li>
      <li parentName="ul">{`Hellsing Ultimate VI`}</li>
      <li parentName="ul">{`Hellsing Ultimate VII`}</li>
      <li parentName="ul">{`Hellsing Ultimate VIII`}</li>
      <li parentName="ul">{`Hellsing Ultimate IX`}</li>
      <li parentName="ul">{`Hellsing Ultimate X`}</li>
      <li parentName="ul">{`Akira`}</li>
      <li parentName="ul">{`Neon Genesis Evangelion Collection 0:1`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Neon Genesis Evangelion (Complete Platnum Collection)</Missing>
      </li>
      <li parentName="ul">{`Neon Genesis Evangelion: Death and Rebirth Movie`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Neon Genesis Evangelion: The End of Evangelion Movie</Missing>
      </li>
      <li parentName="ul">{`Neon Genesis Evangelion: 1.01 You Are (Not) Alone Movie`}</li>
      <li parentName="ul">{`Neon Genesis Evangelion: 2.22 You Can (Not) Advance Movie`}</li>
      <li parentName="ul">{`Kodocha Box Set 1`}</li>
      <li parentName="ul">{`Kodocha Box Set 2`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">
  [Kodocha Series
  2](http://www.force-anime.com/childrens-toy--kodomo-no-omochasecondary-school-3794-p.asp)
        </Missing>
      </li>
      <li parentName="ul">{`D.N. Angel`}</li>
      <li parentName="ul">{`M.D. Geist`}</li>
      <li parentName="ul">{`Mask of Zeguy`}</li>
      <li parentName="ul">{`Nabari Part I`}</li>
      <li parentName="ul">{`Nabari Part II`}</li>
      <li parentName="ul">{`Karin`}</li>
      <li parentName="ul">{`When They Cry`}</li>
      <li parentName="ul">{`Red Garden`}</li>
      <li parentName="ul">{`Princess Resurrection Collection 1`}</li>
      <li parentName="ul">{`Princess Resurrection Collection 2`}</li>
      <li parentName="ul">{`Mezzo Forte (Abridged)`}</li>
      <li parentName="ul">{`Mezzo Danger Service Agency`}</li>
      <li parentName="ul">{`Kyte (Abridged)`}</li>
      <li parentName="ul">{`Kyte: Liberator`}</li>
      <li parentName="ul">{`Mahoromatic`}</li>
      <li parentName="ul">{`Bludgeoning Angel Dokuro-chan`}</li>
      <li parentName="ul">{`Soul Eater Part One`}</li>
      <li parentName="ul">{`Soul Eater Part Two`}</li>
      <li parentName="ul">{`Soul Eater Part Three`}</li>
      <li parentName="ul">{`Soul Eater Part Four`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">
  [Märchen Awakens
  Romance](http://www.force-anime.com/mrchen-awakens-romance-complete-series-3734-p.asp)
        </Missing>
      </li>
      <li parentName="ul">
        <Missing mdxType="Missing">
  Detective Conan Seasons 7+ (import, funimation is too far behind)
        </Missing>
      </li>
      <li parentName="ul">{`Case Closed Double Feature: The Time Bombed Skyscraper & The Fourteenth Target`}</li>
      <li parentName="ul">{`Case Closed Movie: The Last Wizard of the Century`}</li>
      <li parentName="ul">{`Case Closed Movie: Captured in Her Eyes`}</li>
      <li parentName="ul">{`Case Closed Movie: Countdown to Heaven`}</li>
      <li parentName="ul">{`Case Closed Movie: The Phantom of Baker Street`}</li>
      <li parentName="ul">{`All Purpose Cultural Catgirl Nuku Nuku OVA`}</li>
      <li parentName="ul">{`All Purpose Cultural Catgirl Nuku Nuku TV`}</li>
      <li parentName="ul">{`All Purpose Cultural Catgirl Nuku Nuku Dash`}</li>
      <li parentName="ul">{`Shingu`}</li>
      <li parentName="ul">{`Paprika`}</li>
      <li parentName="ul">{`Elemental Gelade`}</li>
      <li parentName="ul">{`xxxHolic`}</li>
      <li parentName="ul">{`xxxHolic The Movie: A Midsummer Night's Dream`}</li>
      <li parentName="ul">{`Astro Boy (Film)`}</li>
      <li parentName="ul">{`Genshiken`}</li>
      <li parentName="ul">{`Genshiken2`}</li>
      <li parentName="ul">{`Kujibiki Unbalance`}</li>
      <li parentName="ul">{`Clockwork Fighters Hiwou's War volumes 1-3`}</li>
      <li parentName="ul">{`Tayotama`}</li>
      <li parentName="ul">{`Nurse Witch Komugi`}</li>
      <li parentName="ul">{`The Soul Taker: The Monster Within`}</li>
      <li parentName="ul">{`The Soul Taker: Flickering Faith`}</li>
      <li parentName="ul">{`The Soul Taker: Blood Betrayal`}</li>
      <li parentName="ul">{`The Soul Taker: The Truth`}</li>
      <li parentName="ul">{`Dokkoida!?`}</li>
      <li parentName="ul">{`Black Jack Box 1`}</li>
      <li parentName="ul">{`Black Jack Box 2`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Black Jack</Missing>
      </li>
      <li parentName="ul">
        <Missing mdxType="Missing">Black Jack: Biohazard (film)</Missing>
      </li>
      <li parentName="ul">{`Black Jack: A surgeon with the hands of god (film)`}</li>
      <li parentName="ul">{`Lucky Star`}</li>
      <li parentName="ul">{`Lucky Star OVA`}</li>
      <li parentName="ul">{`Witchblade`}</li>
      <li parentName="ul">{`Magical Shopping Arcade Abenobashi`}</li>
      <li parentName="ul">{`Tsubaa: The Movie (packaged with xxxHolic movie)`}</li>
      <li parentName="ul">{`To Love-Ru Collection 1`}</li>
      <li parentName="ul">{`To Love-Ru Collection 2`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">To Love-Ru OVA</Missing>
      </li>
      <li parentName="ul">{`Motto To Love-Ru`}</li>
      <li parentName="ul">{`To Love-Ru Darkness`}</li>
      <li parentName="ul">{`The Girl Who Leapt Through Time`}</li>
      <li parentName="ul">{`Devergence Eve`}</li>
      <li parentName="ul">{`Alien Nine`}</li>
      <li parentName="ul">{`Kannagi`}</li>
      <li parentName="ul">{`Ah My Buddha`}</li>
      <li parentName="ul">{`Argentosoma`}</li>
      <li parentName="ul">{`Maria†Holic`}</li>
      <li parentName="ul">{`Golgo 13 Collection 1`}</li>
      <li parentName="ul">{`Golgo 13 Collection 2`}</li>
      <li parentName="ul">{`Golgo 13 The Professional`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Golgo 13: Queen Bee</Missing>
      </li>
      <li parentName="ul">{`UFO Ultramaiden Valkyrie Seasons 1-4 (2-3=OVAs)`}</li>
      <li parentName="ul">{`Solty Rei`}</li>
      <li parentName="ul">{`Tweeny Witches`}</li>
      <li parentName="ul">{`Claymore (complete)`}</li>
      <li parentName="ul">{`Claymore Volumes 3-4 (extras)`}</li>
      <li parentName="ul">{`Rumiko Takahashi Anthology`}</li>
      <li parentName="ul">{`Ranma 1/2 Season 1`}</li>
      <li parentName="ul">{`Ranma 1/2 Season 2`}</li>
      <li parentName="ul">{`Ranma 1/2 Season 3`}</li>
      <li parentName="ul">{`Ranma 1/2 Season 4`}</li>
      <li parentName="ul">{`Ranma 1/2 Season 5`}</li>
      <li parentName="ul">{`Ranma 1/2 Season 6`}</li>
      <li parentName="ul">{`Ranma 1/2 Season 7`}</li>
      <li parentName="ul">{`Ranma 1/2 Movies`}</li>
      <li parentName="ul">{`Ranma 1/2 OVAs`}</li>
      <li parentName="ul">{`Inuyasha Season 1`}</li>
      <li parentName="ul">{`Inuyasha Season 2`}</li>
      <li parentName="ul">{`Inuyasha Season 3`}</li>
      <li parentName="ul">{`Inuyasha Season 4`}</li>
      <li parentName="ul">{`Inuyasha Season 5`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Inuyasha Season 6</Missing>
      </li>
      <li parentName="ul">
        <Missing mdxType="Missing">Inuyasha Season 7</Missing>
      </li>
      <li parentName="ul">
        <Missing mdxType="Missing">Inuyasha Season Movies</Missing>
      </li>
      <li parentName="ul">{`Inuyasha The Movie 4: Fire On the Mystic Island`}</li>
      <li parentName="ul">{`Urusei Yatsura TV.1`}</li>
      <li parentName="ul">{`Urusei Yatsura TV.2`}</li>
      <li parentName="ul">{`Urusei Yatsura TV.3`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Urusei Yatsura</Missing>
      </li>
      <li parentName="ul">{`Maison Ikkoku Collectors Volume 1`}</li>
      <li parentName="ul">{`Maison Ikkoku Collectors Volume 2`}</li>
      <li parentName="ul">{`Maison Ikkoku Collectors Volume 3`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Maison Ikkoku</Missing>
      </li>
      <li parentName="ul">{`Maburaho`}</li>
      <li parentName="ul">{`King of Bandit Jing`}</li>
      <li parentName="ul">{`Jing, King of Bandits: Seventh Heaven`}</li>
      <li parentName="ul">{`Trigun`}</li>
      <li parentName="ul">{`Trigun: Badlands`}</li>
      <li parentName="ul">{`Birdy the Mighty: Decode`}</li>
      <li parentName="ul">{`Now and Then, Here and There`}</li>
      <li parentName="ul">{`Hare+Guu`}</li>
      <li parentName="ul">{`Hare+Guu Deluxe 1`}</li>
      <li parentName="ul">{`Hare+Guu Deluxe 2`}</li>
      <li parentName="ul">{`He is my Master`}</li>
      <li parentName="ul">{`Yuna`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Desert Punk</Missing>
      </li>
      <li parentName="ul">{`Disgaea`}</li>
      <li parentName="ul">{`Excel Saga`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Hand Maid May</Missing>
      </li>
      <li parentName="ul">{`Ghost Talkers Daydream`}</li>
      <li parentName="ul">{`Metropolis`}</li>
      <li parentName="ul">{`Moon Phase`}</li>
      <li parentName="ul">{`Nanaka 6/17`}</li>
      <li parentName="ul">{`Oh! Edo Rocket`}</li>
      <li parentName="ul">{`Origin (Spirits of the Past) the Movie`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Power Dolls</Missing>
      </li>
      <li parentName="ul">{`Risky Safety (Omishi Magical Theater)`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Sayonara Zetsubou-Sensei</Missing>
      </li>
      <li parentName="ul">
        <Missing mdxType="Missing">Serial Experiments Lain</Missing>
      </li>
      <li parentName="ul">
        <Missing mdxType="Missing">Familiar of Zero</Missing>
      </li>
      <li parentName="ul">
        <Missing mdxType="Missing">Shakugan no Shana</Missing>
      </li>
      <li parentName="ul">
        <Missing mdxType="Missing">Stellvia</Missing>
      </li>
      <li parentName="ul">{`The Third: The Girl With the Blue Eye`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Tsukihime Lunar Legend</Missing>
      </li>
      <li parentName="ul">{`Twin Signal`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">D. Gray Man</Missing>
      </li>
      <li parentName="ul">
        <Missing mdxType="Missing">Welcome to the NHK</Missing>
      </li>
      <li parentName="ul">
        <Missing mdxType="Missing">Godainer</Missing>
      </li>
      <li parentName="ul">{`Blue Seed`}</li>
      <li parentName="ul">{`Aquarian Age`}</li>
      <li parentName="ul">{`Chobits`}</li>
      <li parentName="ul">{`Panty and Stocking with Garterbelt`}</li>
      <li parentName="ul">{`Sora no Otoshimono`}</li>
      <li parentName="ul">{`Sora no Otoshimono Forte`}</li>
      <li parentName="ul">{`Sora no Otoshimono Tokei-jikake no Enjeroido`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Gintama</Missing>
      </li>
      <li parentName="ul">{`Gintama: The Movie`}</li>
      <li parentName="ul">{`Gintama: The Movie: The Final Chapter: Be Forever Yorozuya`}</li>
      <li parentName="ul">{`Ikamusume (Squid Girl) Part 1`}</li>
      <li parentName="ul">{`Ikamusume (Squid Girl) Part 2`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Ikamusume (Squid Girl) Part 3</Missing>
      </li>
      <li parentName="ul">{`FLCL`}</li>
      <li parentName="ul">{`Highschool of the Dead`}</li>
      <li parentName="ul">{`Highschool of the Dead: Drifters of the Dead OVA`}</li>
      <li parentName="ul">{`Kore wa Zombie Desu-ka`}</li>
      <li parentName="ul">{`Kore wa Zombie Desu-ka? of the Dead`}</li>
      <li parentName="ul">{`Detroit Metal City`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Detroit Metal City (Live action)</Missing>
      </li>
      <li parentName="ul">{`Nyan-koi!`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Kampfer(?)</Missing>
      </li>
      <li parentName="ul">{`Needless Collection 1`}</li>
      <li parentName="ul">{`Needless Collection 2`}</li>
      <li parentName="ul">{`Our Home's Fox Deity (Wagaya no Oinarisama) Volume 1 (Premium)`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">
  Our Home's Fox Deity (Wagaya no Oinarisama) Volume 2 (Premium)
        </Missing>
      </li>
      <li parentName="ul">{`Sgt. Frog Season One Part 1`}</li>
      <li parentName="ul">{`Sgt. Frog Season One Part 2`}</li>
      <li parentName="ul">{`Sgt. Frog Season Two Part 1`}</li>
      <li parentName="ul">{`Sgt. Frog Season Two Part 2`}</li>
      <li parentName="ul">{`Sgt. Frog Season Three Part 1`}</li>
      <li parentName="ul">{`Sgt. Frog Season Three Part 2`}</li>
      <li parentName="ul">{`Vexile`}</li>
      <li parentName="ul">{`Kakurenbo - Hide & Seek`}</li>
      <li parentName="ul">{`Eiken`}</li>
      <li parentName="ul">{`Ghost Slayers Ayashi`}</li>
      <li parentName="ul">{`Sol Bianca`}</li>
      <li parentName="ul">{`Noir`}</li>
      <li parentName="ul">{`Noir volume 3 (duplicate)`}</li>
      <li parentName="ul">{`Noir volume 4 (duplicate)`}</li>
      <li parentName="ul">{`Noir volume 5 (duplicate)`}</li>
      <li parentName="ul">{`Noir volume 6 (duplicate)`}</li>
      <li parentName="ul">{`Kurau Phantom Memory`}</li>
      <li parentName="ul">{`Madara`}</li>
      <li parentName="ul">{`Yozakura Quartet`}</li>
      <li parentName="ul">{`Azumanga Daioh`}</li>
      <li parentName="ul">{`Seven of Seven`}</li>
      <li parentName="ul">{`Itsudatte My Santa!`}</li>
      <li parentName="ul">{`Space Pirate Mito`}</li>
      <li parentName="ul">{`Aoi & Mitsuki a pair of queens!`}</li>
      <li parentName="ul">{`Battle Angel`}</li>
      <li parentName="ul">{`Persona Trinity Soul`}</li>
      <li parentName="ul">{`Steam Boy`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Flame of Recca</Missing>
      </li>
      <li parentName="ul">{`Venus Versus Virus (Complete Collection)`}</li>
      <li parentName="ul">{`Venus Versus Virus Vol 3 (Redundant)`}</li>
      <li parentName="ul">{`Cyber Team in Akihabara`}</li>
      <li parentName="ul">{`Najica Blitz Tactics`}</li>
      <li parentName="ul">{`Angel Beats`}</li>
      <li parentName="ul">{`Guyver: The Bioboosted Armor`}</li>
      <li parentName="ul">{`Happy Lesson`}</li>
      <li parentName="ul">{`Ninja Resurrection`}</li>
      <li parentName="ul">{`Blood Reign Curse of the Yoma`}</li>
      <li parentName="ul">{`PuniPuni Poemy`}</li>
      <li parentName="ul">{`Kekko Kamen`}</li>
      <li parentName="ul">{`Papuwa Volumes 1-6`}</li>
      <li parentName="ul">{`Burst Angel`}</li>
      <li parentName="ul">{`Devil May Cry`}</li>
      <li parentName="ul">{`Chrono Crusade`}</li>
      <li parentName="ul">{`Sands of Destruction`}</li>
      <li parentName="ul">{`Murder Princess`}</li>
      <li parentName="ul">{`Karas`}</li>
      <li parentName="ul">{`Karas: The Prophecy (duplicate)`}</li>
      <li parentName="ul">{`Coyote Ragtime Show`}</li>
      <li parentName="ul">{`Plastic Little`}</li>
      <li parentName="ul">{`Burn Up`}</li>
      <li parentName="ul">{`Mega Zone 23`}</li>
      <li parentName="ul">{`Blue Gender: The Warrior`}</li>
      <li parentName="ul">{`Eden's Bowy`}</li>
      <li parentName="ul">{`Arcade Gamer Fubuki`}</li>
      <li parentName="ul">{`Neo Ranga`}</li>
      <li parentName="ul">{`Baldr Force Exe`}</li>
      <li parentName="ul">{`Tekkon Kinkreet`}</li>
      <li parentName="ul">{`The World of Narue`}</li>
      <li parentName="ul">{`Steam Detectives`}</li>
      <li parentName="ul">{`E's Otherwise`}</li>
      <li parentName="ul">{`Hammerboy`}</li>
      <li parentName="ul">{`Blade of the Phantom Master`}</li>
      <li parentName="ul">{`Gasaraki`}</li>
      <li parentName="ul">{`Casshern Sins Part One`}</li>
      <li parentName="ul">{`Casshern Sins Part Two`}</li>
      <li parentName="ul">{`Himawari Season 1`}</li>
      <li parentName="ul">{`Himawari Season 2`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Full Metal Panic</Missing>
      </li>
      <li parentName="ul">{`Full Metal Panic? Fummofu`}</li>
      <li parentName="ul">{`Full Metal Panic!: The Second Raid`}</li>
      <li parentName="ul">{`Madlax`}</li>
      <li parentName="ul">{`A Tree of Palme`}</li>
      <li parentName="ul">{`My Beautiful Girl Mari`}</li>
      <li parentName="ul">{`Chaos;Head`}</li>
      <li parentName="ul">{`Akikan`}</li>
      <li parentName="ul">{`Wandaba Style`}</li>
      <li parentName="ul">{`Dragon Ball Season 1`}</li>
      <li parentName="ul">{`Dragon Ball Season 2`}</li>
      <li parentName="ul">{`Dragon Ball Season 3`}</li>
      <li parentName="ul">{`Dragon Ball Season 4`}</li>
      <li parentName="ul">{`Dragon Ball Season 5`}</li>
      <li parentName="ul">{`Dragon Ball Movie Collection One`}</li>
      <li parentName="ul">{`Dragon Ball Z Movie Collection Two`}</li>
      <li parentName="ul">{`Dragon Ball Z Movie Collection Three`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Dr. Slump</Missing>
      </li>
      <li parentName="ul">{`Dr. Slump The Movies (1-5)`}</li>
      <li parentName="ul">{`Arakawa Under the Bridge (Premium Edition)`}</li>
      <li parentName="ul">{`Arakawa 2 Under the Bridge X Bridge (Premium Edition)`}</li>
      <li parentName="ul">{`Dream Eater Merry`}</li>
      <li parentName="ul">{`Aika`}</li>
      <li parentName="ul">{`Aika (R-16)`}</li>
      <li parentName="ul">{`Baccano!`}</li>
      <li parentName="ul">{`The Skycrawlers`}</li>
      <li parentName="ul">{`Figure 17`}</li>
      <li parentName="ul">{`Midori Days`}</li>
      <li parentName="ul">{`Ghastly Prince Enma Burning Up (Premium Edition)`}</li>
      <li parentName="ul">{`Outlaw Star`}</li>
      <li parentName="ul">{`Phantom Quest Corp.`}</li>
      <li parentName="ul">{`Nadia: The Secret of Blue Water`}</li>
      <li parentName="ul">{`Ground Control to Psychoelectric Girl (Premium)`}</li>
      <li parentName="ul">{`Doki Doki School Hours (+Soundtrack)`}</li>
      <li parentName="ul">{`Ghost in the Shell 2: Innocence`}</li>
      <li parentName="ul">{`Ergo Proxy`}</li>
      <li parentName="ul">{`Gyo: Tokyo Fish Attack!`}</li>
      <li parentName="ul">{`Future Diary Part 1`}</li>
      <li parentName="ul">{`Future Diary Part 2`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Corpse Party: Tortured Souls</Missing>
      </li>
      <li parentName="ul">{`Redline`}</li>
      <li parentName="ul">{`Wagnaria!! Complete Series`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Wagnaria!!2</Missing>
      </li>
      <li parentName="ul">{`Zatch Bell: Attack of Mechavulcan (Imported, Caseless)`}</li>
      <li parentName="ul">{`Summer Wars`}</li>
      <li parentName="ul">{`Wolf Children`}</li>
      <li parentName="ul">{`Someday's Dreamers`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Someday's Dreamers II Sora</Missing>
      </li>
      <li parentName="ul">{`Perfect Blue`}</li>
      <li parentName="ul">{`Short Peace`}</li>
      <li parentName="ul">{`Tofu Kozo`}</li>
      <li parentName="ul">{`Bayonetta: Bloody Fate`}</li>
      <li parentName="ul">{`Mega Man: The Complete Series`}</li>
      <li parentName="ul">{`Blood Lad`}</li>
      <li parentName="ul">{`Creamy Mami`}</li>
      <li parentName="ul">{`3x3 Eyes`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Madoka Movies</Missing>
      </li>
      <li parentName="ul">{`Coluboccoro`}</li>
      <li parentName="ul">{`Cencoroll`}</li>
    </ul>
    <Heading title="Films" mdxType="Heading" />
    <ul>
      <li parentName="ul">{`Matrix`}</li>
      <li parentName="ul">{`Matrix Reloaded`}</li>
      <li parentName="ul">{`Matrix Revolutions`}</li>
      <li parentName="ul">{`Animatrix`}</li>
      <li parentName="ul">{`Resident Evil`}</li>
      <li parentName="ul">{`Resident Evil: Apocalypse`}</li>
      <li parentName="ul">{`Resident Evil: Extinction`}</li>
      <li parentName="ul">{`Resident Evil: Afterlife`}</li>
      <li parentName="ul">{`Resident Evil: Retribution`}</li>
      <li parentName="ul">{`Resident Evil: Degeneration`}</li>
      <li parentName="ul">{`Resident Evil: Damnation`}</li>
      <li parentName="ul">{`Harry Potter and the Philosopher's Stone`}</li>
      <li parentName="ul">{`Harry Potter and the Chamber of Secrets`}</li>
      <li parentName="ul">{`Harry Potter and the Prisoner of Azkaban`}</li>
      <li parentName="ul">{`Harry Potter and the Goblet of Fire`}</li>
      <li parentName="ul">{`Harry Potter and the Order of the Phoenix`}</li>
      <li parentName="ul">{`Harry Potter and the Half-Blood Prince`}</li>
      <li parentName="ul">{`Harry Potter and the Deathly Hallows Part 1`}</li>
      <li parentName="ul">{`Harry Potter and the Deathly Hallows Part 2`}</li>
      <li parentName="ul">{`The Evil Dead`}</li>
      <li parentName="ul">{`Evil Dead 2`}</li>
      <li parentName="ul">{`Clockstoppers`}</li>
      <li parentName="ul">{`The Master of Disguise`}</li>
      <li parentName="ul">{`The Ring`}</li>
      <li parentName="ul">{`The Ring Two`}</li>
      <li parentName="ul">{`Ghosts of Mars`}</li>
      <li parentName="ul">{`Queen of the Damned`}</li>
      <li parentName="ul">{`Ghost Ship`}</li>
      <li parentName="ul">{`Superhero Movie`}</li>
      <li parentName="ul">{`Hitchhiker's Guide to the Galaxy`}</li>
      <li parentName="ul">{`The Uninvited`}</li>
      <li parentName="ul">{`A Tale of Two Sisters`}</li>
      <li parentName="ul">{`Premonition`}</li>
      <li parentName="ul">{`Dead Like Me: Life After Death`}</li>
      <li parentName="ul">{`Eagle Eye`}</li>
      <li parentName="ul">{`Land of the Dead (Disk only)`}</li>
      <li parentName="ul">{`Little Nicky`}</li>
      <li parentName="ul">{`Dragonball Evolution (`}<em parentName="li">{`cough`}</em>{` What can I say, it was free...)`}</li>
      <li parentName="ul">{`Underworld (2003)`}</li>
      <li parentName="ul">{`Underworld: Evolution`}</li>
      <li parentName="ul">{`Underworld: Rise of the Lycans`}</li>
      <li parentName="ul">{`Underworld: Awakening`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Van Helsing</Missing>
      </li>
      <li parentName="ul">{`Van Helsing: The London Assignment`}</li>
      <li parentName="ul">{`Blade`}</li>
      <li parentName="ul">{`Blade II`}</li>
      <li parentName="ul">{`Blade Trinity`}</li>
      <li parentName="ul">{`Bloodrayne`}</li>
      <li parentName="ul">{`Bloodrayne 2`}</li>
      <li parentName="ul">{`Aeon Flux`}</li>
      <li parentName="ul">{`UltraViolet`}</li>
      <li parentName="ul">{`Death to Smoochy`}</li>
      <li parentName="ul">{`Idle Hands`}</li>
      <li parentName="ul">{`Mirrors`}</li>
      <li parentName="ul">{`Shutter`}</li>
      <li parentName="ul">{`The Green Hornet`}</li>
      <li parentName="ul">{`The Host`}</li>
      <li parentName="ul">{`Drag me to Hell`}</li>
      <li parentName="ul">{`Robot / Enthiran`}</li>
      <li parentName="ul">{`Superman`}</li>
      <li parentName="ul">{`Superman II`}</li>
      <li parentName="ul">{`Superman III`}</li>
      <li parentName="ul">{`Superman IV`}</li>
      <li parentName="ul">{`Equilibrium`}</li>
      <li parentName="ul">{`My Favorite Martian`}</li>
      <li parentName="ul">{`28 Days Later`}</li>
      <li parentName="ul">{`Thirst`}</li>
      <li parentName="ul">{`Monkeybone`}</li>
      <li parentName="ul">{`Kick Ass`}</li>
      <li parentName="ul">{`Kick Ass 2`}</li>
      <li parentName="ul">{`Monty Python and the Holy Grail`}</li>
      <li parentName="ul">{`The Orphanage`}</li>
      <li parentName="ul">{`Rammbock: Berlin Undead`}</li>
      <li parentName="ul">{`The Crazies`}</li>
      <li parentName="ul">{`Oblivion Island`}</li>
      <li parentName="ul">{`Lucio Fulci's Zombie`}</li>
      <li parentName="ul">{`The Return of The Living Dead`}</li>
      <li parentName="ul">{`Slither`}</li>
      <li parentName="ul">{`Altered`}</li>
      <li parentName="ul">{`Cry_Wolf`}</li>
      <li parentName="ul">{`The Return`}</li>
      <li parentName="ul">{`Happiness of the Katakuris`}</li>
      <li parentName="ul">{`The Kingdom of Dreams and Madness`}</li>
      <li parentName="ul">{`Dr. Horrible's Sing-Along Blog`}</li>
      <li parentName="ul">{`Dead Snow`}</li>
      <li parentName="ul">{`Dead Sknow 2`}</li>
      <li parentName="ul">{`Kikujiro`}</li>
      <li parentName="ul">{`Re-Animator`}</li>
      <li parentName="ul">{`Pontypool`}</li>
      <li parentName="ul">{`The Triplets of Belleville`}</li>
      <li parentName="ul">{`Rec`}</li>
    </ul>
    <Heading title="Animated Films" mdxType="Heading" />
    <ul>
      <li parentName="ul">{`Coraline`}</li>
      <li parentName="ul">{`9`}</li>
      <li parentName="ul">{`The Nightmare Before Christmas`}</li>
      <li parentName="ul">{`Corpse Bride`}</li>
      <li parentName="ul">{`Monsters vs. Aliens`}</li>
      <li parentName="ul">{`Bob's Big Break`}</li>
      <li parentName="ul">{`Despicable Me`}</li>
      <li parentName="ul">{`Despicable Me 2`}</li>
      <li parentName="ul">{`Minions`}</li>
      <li parentName="ul">{`Megamind`}</li>
      <li parentName="ul">{`Megamind: The Button of Doom`}</li>
      <li parentName="ul">{`Toy Story`}</li>
      <li parentName="ul">{`Toy Story 2`}</li>
      <li parentName="ul">{`Toy Story 3`}</li>
      <li parentName="ul">{`Cloudy with a chance of meatballs`}</li>
      <li parentName="ul">{`Cloudy with a chance of meatballs 2`}</li>
      <li parentName="ul">{`Frankenweenie`}</li>
      <li parentName="ul">{`Hotel Transylvania`}</li>
      <li parentName="ul">{`Hotel Transylvania 2`}</li>
      <li parentName="ul">{`Galerians: Rion`}</li>
      <li parentName="ul">{`Wreck It Ralph`}</li>
    </ul>
    <Heading title="Asian Films" mdxType="Heading" />
    <ul>
      <li parentName="ul">{`Rainbow Kids`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.imdb.com/title/tt0891520/"
        }}>{`Kuchisake-Onna (Carved: The Slit-Mouthed Woman)`}</a></li>
      <li parentName="ul">{`The Machine Girl`}</li>
      <li parentName="ul">{`Tokyo Gore Police`}</li>
      <li parentName="ul">{`Meatball Machine`}</li>
      <li parentName="ul">{`Robo Geisha`}</li>
      <li parentName="ul">{`Kwaidan`}</li>
      <li parentName="ul">{`Tokyo Shock Triple Feature: Japaneses Hell, Cursed (2004), and Samurai chicks`}</li>
      <li parentName="ul">{`Assault Girls`}</li>
      <li parentName="ul">{`Varmpire Girl vs Frankenstein Girl`}</li>
      <li parentName="ul">{`Synesthesia`}</li>
      <li parentName="ul">{`Hansel & Gretel`}</li>
      <li parentName="ul">{`Nobuhiko Obayashi's "House" The Criterion Collection`}</li>
      <li parentName="ul">{`Ju-On`}</li>
      <li parentName="ul">{`Ju-On 2`}</li>
      <li parentName="ul">{`Ringu (Caseless)`}</li>
      <li parentName="ul">{`Dark Water (2002)`}</li>
      <li parentName="ul">{`Dark Water (2005)`}</li>
      <li parentName="ul">{`Battle Royale (Blue-Ray Collector's Complete Collection)`}</li>
      <li parentName="ul">{`Casshern`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Tomie</Missing>
      </li>
      <li parentName="ul">
        <Missing mdxType="Missing">Tomie: Another Face</Missing>
      </li>
      <li parentName="ul">
        <Missing mdxType="Missing">Tomie: Replay</Missing>
      </li>
      <li parentName="ul">
        <Missing mdxType="Missing">Tomie: Re-birth</Missing>
      </li>
      <li parentName="ul">
        <Missing mdxType="Missing">Tomie: Forbidden Fruit</Missing>
      </li>
      <li parentName="ul">
        <Missing mdxType="Missing">Tomie: Beginning</Missing>
      </li>
      <li parentName="ul">
        <Missing mdxType="Missing">Tomie: Revenge</Missing>
      </li>
      <li parentName="ul">{`Tomie vs Tomie`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Tomie: Unlimited</Missing>
      </li>
      <li parentName="ul">{`Love Exposure`}</li>
      <li parentName="ul">{`Boku no kanojo wa saibôgu`}</li>
      <li parentName="ul">{`Meitantei Conan: Kudo Shinichi he no Chosenjo (Live Action; TV Special 1)`}</li>
      <li parentName="ul">{`Seishun Kinzoku Batto`}</li>
      <li parentName="ul">{`恋する 日曜日 ニュー タイプ (Import)`}</li>
      <li parentName="ul">{`((Any Japanese horror, primarily those based on old Japanese mythology will do...))`}</li>
    </ul>
    <Heading title="Other" mdxType="Heading" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.animecornerstore.com/aeonflux.html"
        }}>{`Æon Flux (animated)`}</a></li>
      <li parentName="ul">{`Teen Titans Seasons 1-5`}</li>
      <li parentName="ul">{`Teen Titans: Trouble in Tokyo`}</li>
      <li parentName="ul">{`Doctor Who: Complete First Series`}</li>
      <li parentName="ul">{`Doctor Who: Complete Second Series`}</li>
      <li parentName="ul">{`Doctor Who: Complete Third Series`}</li>
      <li parentName="ul">{`Doctor Who: Complete Fourth Series`}</li>
      <li parentName="ul">{`Doctor Who: Complete Specials`}</li>
      <li parentName="ul">{`Doctor Who: Complete Fifth Series`}</li>
      <li parentName="ul">{`Doctor Who: "A Christmas Carol" (2010 Special) (Redundant)`}</li>
      <li parentName="ul">{`Doctor Who: Complete Sixth Series`}</li>
      <li parentName="ul">{`Doctor Who: Complete Seventh Series`}</li>
      <li parentName="ul">{`Doctor Who: "The Day of the Doctor"`}</li>
      <li parentName="ul">{`Doctor Who: "The Time of the Doctor"`}</li>
      <li parentName="ul">{`Doctor Who: Complete Eighth Series`}</li>
      <li parentName="ul">{`Wizards vs Aliens: Series 1`}</li>
      <li parentName="ul">{`Wizards vs Aliens: Series 2`}</li>
      <li parentName="ul">{`RWBY Season 1`}</li>
      <li parentName="ul">{`RWBY Season 2`}</li>
      <li parentName="ul">{`Wakfu`}</li>
    </ul>
    <Heading title="Manga" mdxType="Heading" />
    <ul>
      <li parentName="ul">{`Soul Eater Volumes 1-2`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Soul Eater Volumes 3-4</Missing>
      </li>
      <li parentName="ul">{`Gyakushu! Volumes 1-2`}</li>
      <li parentName="ul">{`Oninagi Volumes 1-3`}</li>
      <li parentName="ul">{`Princess Resurrection Volumes 1-7`}</li>
      <li parentName="ul">{`O-Parts Hunter Volumes 1-19`}</li>
      <li parentName="ul">{`Future Diary Volumes 1-10`}</li>
      <li parentName="ul">{`Maid War Volumes 1-2`}</li>
      <li parentName="ul">{`Animal Academy Volumes 1-7`}</li>
      <li parentName="ul">{`King of Debris Volumes 1-2`}</li>
      <li parentName="ul">{`Momo Tama Volumes 1-4`}</li>
      <li parentName="ul">{`St. Lunatic High School Volumes 1-2`}</li>
      <li parentName="ul">{`Cirque Du Freak Volume 1-7`}</li>
      <li parentName="ul">{`Shadow Star Volumes 1-7`}</li>
      <li parentName="ul">{`Laon Volumes 1-6`}</li>
      <li parentName="ul">{`Classical Meledy Volumes 1-2`}</li>
      <li parentName="ul">{`Dragon Head Volumes 1-10`}</li>
      <li parentName="ul">{`Jormungand Volumes 1-3`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Jormungand Volumes 4-5</Missing>
      </li>
      <li parentName="ul">{`Dragon Eye Volumes 1-8`}</li>
      <li parentName="ul">{`Sundome 1-8`}</li>
      <li parentName="ul">{`Ghost Talker's Daydream Volume 1`}</li>
      <li parentName="ul">{`Hanako and the Terror of Allegory Volumes 1-3`}</li>
      <li parentName="ul">{`Black Butler Volume 1`}</li>
      <li parentName="ul">{`1520 Volume 1`}</li>
      <li parentName="ul">{`Zombie Fairy Volume 1`}</li>
      <li parentName="ul">{`Crimson-Shell Volume 1`}</li>
      <li parentName="ul">{`Mr. Grieves Volume 1`}</li>
      <li parentName="ul">{`Jack Frost 1-5`}</li>
      <li parentName="ul">{`Madara Volumes 1-5`}</li>
      <li parentName="ul">{`Dead Already Volume 1`}</li>
      <li parentName="ul">{`Deadman Wonderland Volumes 1-4`}</li>
      <li parentName="ul">{`Deus Vitae Volume 1`}</li>
      <li parentName="ul">{`Taimashin: The Red Spider Exorcist Volumes 1-2`}</li>
      <li parentName="ul">{`Aion Volumes 1-2`}</li>
    </ul>
    <Heading title="Yen+ (Yen Plus Magazine)" mdxType="Heading" />
    <ul>
      <li parentName="ul">{`November 2008 / Vol. 1 No. 4`}</li>
      <li parentName="ul">{`December 2008 / Vol. 1 No. 5`}</li>
      <li parentName="ul">{`January 2009 / Vol. 2 No. 1`}</li>
      <li parentName="ul">{`February 2009 / Vol. 2 No. 2`}</li>
      <li parentName="ul">{`March 2009 / Vol. 2 No. 3`}</li>
      <li parentName="ul">{`April 2009 / Vol. 2 No. 4`}</li>
      <li parentName="ul">{`May 2009 / Vol. 2 No. 5`}</li>
      <li parentName="ul">{`June 2009 / Vol. 2 No. 6`}</li>
      <li parentName="ul">{`July 2009 / Vol. 2 No. 7`}</li>
      <li parentName="ul">{`cd August 2009 / Vol. 2 No. 8`}</li>
      <li parentName="ul">{`September 2009 / Vol. 2 No. 9`}</li>
      <li parentName="ul">{`October 2009 / Vol. 2 No. 10`}</li>
      <li parentName="ul">{`November 2009 / Vol. 2 No. 11`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">December 2009 / Vol. 2 No. 12</Missing>
      </li>
      <li parentName="ul">{`January 2010 / Vol. 3 No. 1`}</li>
      <li parentName="ul">{`February 2010 / Vol. 3 No. 2`}</li>
      <li parentName="ul">{`March 2010 / Vol. 3 No. 3`}</li>
      <li parentName="ul">{`April 2010 / Vol. 3 No. 4`}</li>
      <li parentName="ul">{`May 2010 / Vol. 3 No. 5`}</li>
      <li parentName="ul">{`June 2010 / Vol. 3 No. 6`}</li>
      <li parentName="ul">{`July 2010 / Vol. 3 No. 7`}</li>
    </ul>
    <Heading title="Video Games (GC)" mdxType="Heading" />
    <ul>
      <li parentName="ul">{`Paper Mario the Thousand Year Door`}</li>
      <li parentName="ul">{`Enter the Matrix`}</li>
      <li parentName="ul">{`Army Men: Sarge's War`}</li>
      <li parentName="ul">{`Soul Calibur II`}</li>
      <li parentName="ul">{`Mega Man Network Transmission`}</li>
      <li parentName="ul">{`Lego Star Wars The Video Game`}</li>
      <li parentName="ul">{`Lego Star Wars II The Original Trillogy`}</li>
      <li parentName="ul">{`Smash Bros. Melee`}</li>
      <li parentName="ul">{`Super Mario Sunshine`}</li>
      <li parentName="ul">{`Geist`}</li>
      <li parentName="ul">{`007 Nightfire`}</li>
      <li parentName="ul">{`007 Everything or Nothing`}</li>
      <li parentName="ul">{`Luigi's Mansion`}</li>
      <li parentName="ul">{`Legend of Zelda the Windwaker (Scratched)`}</li>
      <li parentName="ul">{`Legend of Zelda the Windwaker (Replacement)`}</li>
      <li parentName="ul">{`Goldeneye: Rogue Agent`}</li>
    </ul>
    <Heading title="Video Games (Wii)" mdxType="Heading" />
    <ul>
      <li parentName="ul">{`Super Mario Galaxy`}</li>
      <li parentName="ul">{`Super Mario Galaxy 2`}</li>
      <li parentName="ul">{`Mario Cart Wii`}</li>
      <li parentName="ul">{`Super Paper Mario`}</li>
      <li parentName="ul">{`deBlob`}</li>
      <li parentName="ul">{`Death Jr. Root of Evil`}</li>
      <li parentName="ul">{`Sonic Unleashed`}</li>
      <li parentName="ul">{`Resident Evil The Umbrella Chronicles`}</li>
      <li parentName="ul">{`Dead Space Extraction`}</li>
      <li parentName="ul">{`Red Steel`}</li>
      <li parentName="ul">{`Red Steel 2`}</li>
      <li parentName="ul">{`Lego Batman The Videogame`}</li>
      <li parentName="ul">{`Lego Star Wars The Complete Saga`}</li>
      <li parentName="ul">{`Lego Pirates of the Caribbean`}</li>
      <li parentName="ul">{`Legend of Zelda the Twilight Princess`}</li>
      <li parentName="ul">{`Legend of Zelda the Skyward Sword`}</li>
      <li parentName="ul">{`Okami`}</li>
      <li parentName="ul">{`Super Smash Bros. Brawl`}</li>
      <li parentName="ul">{`Metroid Prime Trilogy (In Metal Collectors Edition Case)`}</li>
      <li parentName="ul">{`Metroid Other M`}</li>
      <li parentName="ul">{`No More Heros`}</li>
      <li parentName="ul">{`No More Heros 2 Desperate Struggle`}</li>
      <li parentName="ul">{`Star Wars The Force Unleashed`}</li>
      <li parentName="ul">{`Star Wars The Force Unleashed II`}</li>
      <li parentName="ul">{`Kirby's Epic Yarn`}</li>
      <li parentName="ul">{`Kirby's Return to DreamLand`}</li>
      <li parentName="ul">{`Samurai Warriors 3`}</li>
      <li parentName="ul">{`Conduit`}</li>
      <li parentName="ul">{`007 Goldeneye`}</li>
      <li parentName="ul">{`Destroy All Humans! Big Willy Unleashed`}</li>
      <li parentName="ul">{`Prince of Persia: The Forgotten Sands`}</li>
      <li parentName="ul">{`Prince of Persia: Rival Swords`}</li>
      <li parentName="ul">{`Pikmin 2`}</li>
      <li parentName="ul">{`New Super Mario Bros. Wii`}</li>
    </ul>
    <Heading title="Video Games (DS)" mdxType="Heading" />
    <ul>
      <li parentName="ul">{`Legend of Zelda: Phantom Hourglass`}</li>
      <li parentName="ul">{`Legend of Zelda: Spirit Tracks`}</li>
      <li parentName="ul">{`Megaman StarForce 3 Red Joker`}</li>
      <li parentName="ul">{`Megaman ZX`}</li>
      <li parentName="ul">{`Megaman Zero Collection`}</li>
      <li parentName="ul">{`Metroid Prime Hunters`}</li>
      <li parentName="ul">{`Advance Wars: Days of Ruin`}</li>
    </ul>
    <Heading title="Video Games (PS2; No console yet)" mdxType="Heading" />
    <ul>
      <li parentName="ul">{`Ratchet & Clank`}</li>
      <li parentName="ul">{`Ratchet: Deadlocked`}</li>
      <li parentName="ul">{`Ratchet & Clank: Up Your Arsenal`}</li>
    </ul>
    <Heading title="Video Games (PS3; Physical)" mdxType="Heading" />
    <ul>
      <li parentName="ul">{`Ratchet & Clank Future: A Crack in Time`}</li>
      <li parentName="ul">{`Ratchet & Clank Future: Tools of Destruction`}</li>
      <li parentName="ul">{`Ratchet & Clank: All 4 One`}</li>
      <li parentName="ul">{`TimeShift`}</li>
      <li parentName="ul">{`Prince of Persia`}</li>
      <li parentName="ul">{`inFAMOUS 2`}</li>
      <li parentName="ul">{`Alice: Madness Returns`}</li>
      <li parentName="ul">{`Vanquish`}</li>
      <li parentName="ul">{`The Orange Box (incl. Portal)`}</li>
      <li parentName="ul">{`Portal 2`}</li>
      <li parentName="ul">{`Grand Theft Auto IV: The Complete Edition`}</li>
      <li parentName="ul">{`Catherine`}</li>
      <li parentName="ul">{`Lego Harry Potter Years 1-4`}</li>
      <li parentName="ul">{`Lego Harry Potter Years 5-7`}</li>
      <li parentName="ul">{`Lego Lord of the Rings`}</li>
      <li parentName="ul">{`Dead Island`}</li>
      <li parentName="ul">{`Deus Ex Human Revolution`}</li>
      <li parentName="ul">{`The Darkness`}</li>
      <li parentName="ul">{`The Darkness 2`}</li>
      <li parentName="ul">{`Binary Domain`}</li>
      <li parentName="ul">{`Prototype 2`}</li>
      <li parentName="ul">{`Mugen Souls`}</li>
      <li parentName="ul">{`Disgaea 3: Absense of Justice`}</li>
      <li parentName="ul">{`Disgaea 4: A Promise Unforgotten`}</li>
      <li parentName="ul">{`Ni No Kuni`}</li>
      <li parentName="ul">{`Devil May Cry HD Collection`}</li>
      <li parentName="ul">{`Saints Row IV`}</li>
      <li parentName="ul">{`Beyond: Two Souls`}</li>
      <li parentName="ul">{`Batman: Arkham Origins`}</li>
    </ul>
    <Heading title="Video Games (Wii U; Physical)" mdxType="Heading" />
    <ul>
      <li parentName="ul">{`Nintendo Land`}</li>
      <li parentName="ul">{`Warriors 3 Orochi Hyper`}</li>
      <li parentName="ul">{`Pikmin 3`}</li>
      <li parentName="ul">{`The Wonderful 101`}</li>
      <li parentName="ul">{`Legend of Zelda the Windwaker HD`}</li>
      <li parentName="ul">{`Hyrule Warriors`}</li>
      <li parentName="ul">{`Bayonetta 2`}</li>
      <li parentName="ul">{`Super Smash Bros. for Wii U`}</li>
    </ul>
    <Heading title="Video Games (PS3; Physical)" mdxType="Heading" />
    <ul>
      <li parentName="ul">{`inFAMOUS: Second Son`}</li>
      <li parentName="ul">{`Dragon Age: Inquisition`}</li>
      <li parentName="ul">{`Far Cry 4`}</li>
      <li parentName="ul">
        <Missing mdxType="Missing">Lego Batman 3</Missing>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      